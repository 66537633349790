import { Panel } from "primereact/panel";
import { Header } from '../component/header';
import { Footer } from '../component/footer';
import { Loading } from "../component/loading";
import { useEffect, useState, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { Navigate } from "react-router-dom";
import { createTahanan, deleteTahanan, editTahanan, getTahanan } from "../service/Tahanan";
        

export const Tahanan = (props) => {
    
    const [authenticated, setAuthenticated] = useState(null);
    const [waitAuthenticated, setWaitAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const toast = useRef(null);

    const [globalFilter, setGlobalFilter] = useState('');
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalData, setTotalData] = useState(0);

    const [popupDialog, setPopupDialog] = useState(false);
    const [statusFlag, setStatusFlag] = useState('');
    const [id, setId] = useState('');
    const [namaTahanan, setNamaTahanan] = useState('');
    const [tglMasuk, setTglMasuk] = useState('');
    const [tglKeluar, setTglKeluar] = useState('');

    const [listDataTable, setListDataTable] = useState([]);

    const fetchData = async (filter) => {
        setIsLoading(true);
        try {
            let listTahanan = await getTahanan(filter);
            if (listTahanan.response_code !== "00") {
                throw listTahanan;
            }
            setListDataTable(listTahanan.data);
            setTotalData(listTahanan.totalData);
        } catch (error) {
            if (error.response_code === 'ET'){
                localStorage.setItem("tokenExpired", true);
                setAuthenticated(false);
                return;
            } else {
                toast.current.show({ severity: 'error', summary: 'Gagal Mendapatkan Data Tahanan', detail: error.response_message, life: 2000 });
                setListDataTable([]);
                setTotalData(0);
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (!authenticated) {
            const loggedInUser = localStorage.getItem("authenticated");
            if (loggedInUser) {
                setAuthenticated(loggedInUser);
                setWaitAuthenticated(true);
                const filter = {
                    search: globalFilter,
                    first: first,
                    rows: rows
                }
                fetchData(filter);
            }else{
                setWaitAuthenticated(true);
            }
        }else{
            const filter = {
                search: globalFilter,
                first: first,
                rows: rows
            }
            fetchData(filter);
        }
    }, [refresh]);

    const modalDialog = (name, data) => {
        setStatusFlag(name);
        if(name === 'popupEdit'){
            setId(data.id);
            setNamaTahanan(data.namaTahanan);
            setTglMasuk(data.tglMasuk);
            setTglKeluar(data.tglKeluar);
        } else {
            setId('');
            setNamaTahanan('');
            setTglMasuk('');
            setTglKeluar('');
        }

        setPopupDialog(true);
    }

    const onClickDelete = async (data) => {
        try {
            setIsLoading(true)
            let deleteDataTahanan = await deleteTahanan(data.id);
            if (deleteDataTahanan.response_code !== "00") {
                throw deleteDataTahanan;
            }
            toast.current.show({ severity: 'success', summary: 'Hapus Tahanan Berhasil', detail: 'Tahanan berhasil dihapus', life: 2000 });
        } catch (error) {
            if (error.response_code === 'ET'){
                localStorage.setItem("tokenExpired", true);
                setAuthenticated(false);
                return;
            } else {
                toast.current.show({ severity: 'error', summary: 'Gagal Menghapus Tahanan', detail: error.response_message, life: 2000 });
            }
        }
        setRefresh(refresh+1);
    }

    const reject = () => {
    }

    const confirmDelete = (data) => {
        confirmDialog({
            message: 'Apakah anda yakin mau menghapus tahanan ini?',
            header: 'Hapus Tahanan',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Hapus',
            rejectLabel: 'Batal',
            accept: () => onClickDelete(data),
            reject: reject
        });
    };

    const onClickSave = (name) => {
        if (namaTahanan && tglMasuk && tglKeluar) {
            saveData(name);
        }
    }

    const saveData = async (name) => {
        if (name === 'popupEdit'){
            try {
                setIsLoading(true);
                const data = {
                    id: id,
                    namaTahanan: namaTahanan,
                    tglMasuk: tglMasuk,
                    tglKeluar: tglKeluar
                }

                let editDataTahanan = await editTahanan(data);
                if (editDataTahanan.response_code !== "00") {
                    throw editDataTahanan;
                }
                toast.current.show({ severity: 'success', summary: 'Edit Tahanan Berhasil', detail: 'Tahanan berhasil edit', life: 2000 });
            } catch (error) {
                if (error.response_code === 'ET'){
                    localStorage.setItem("tokenExpired", true);
                    setAuthenticated(false);
                    return;
                } else {
                    toast.current.show({ severity: 'error', summary: 'Gagal Mengedit Tahanan', detail: error.response_message, life: 2000 });
                }
            }
        } else {
            try {
                setIsLoading(true);
                const data = {
                    namaTahanan: namaTahanan,
                    tglMasuk: tglMasuk,
                    tglKeluar: tglKeluar
                }

                let createDataTahanan = await createTahanan(data);
                if (createDataTahanan.response_code !== "00") {
                    throw createDataTahanan;
                }
                toast.current.show({ severity: 'success', summary: 'Tambah Tahanan Berhasil', detail: 'Tahanan berhasil ditambahkan', life: 2000 });
            } catch (error) {
                if (error.response_code === 'ET'){
                    localStorage.setItem("tokenExpired", true);
                    setAuthenticated(false);
                    return;
                } else {
                    toast.current.show({ severity: 'error', summary: 'Gagal Menambahkan Tahanan', detail: error.response_message, life: 2000 });
                }
            }
        }
        setPopupDialog(false);
        setRefresh(refresh+1);
    }

    useEffect(() => {
        if (authenticated) {
            const delaySearch = setTimeout(async () => {
                const filter = {
                    search: globalFilter,
                    first: first,
                    rows: rows
                }
                fetchData(filter);
            }, 1000);
    
            return () => clearTimeout(delaySearch);
        }
    }, [globalFilter])

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);

        const filter = {
            search: globalFilter,
            first: event.first,
            rows: event.rows
        }
        fetchData(filter);
    };

    let header = (
        <div className="grid">
            <div className="col-12" style={{ padding: "0px" }}>
                <div className="field grid" style={{ padding: "15px 10px 5px 10px", margin: '0px' }}>
                    <div className="col-1">
                        <Button label="Tambah" style={{fontSize: '14px', height: '40px'}} icon="pi pi-plus" onClick={() => modalDialog('popupAdd')}></Button>
                    </div>
                    <div className="col-5">
                        <div style={{ fontSize: '14px', margin: '0 0 0 20px' }}>Kuning = Sisa hari keluar 6-10 hari</div>
                        <div style={{ fontSize: '14px', margin: '5px 0 0 20px' }}>Merah = Sisa hari keluar 0-5 hari</div>
                    </div>
                    <div className="col-6" style={{ textAlign: "right" }}>
                        <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                        <InputText type="search" style={{fontSize: '14px', height: '40px'}} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search" />
                    </div>
                </div>
            </div>
        </div>
    );

    const rowClass = (data) => {
        return {
            'bg-white': data.sisaAngka < 0,
            'bg-red-300': data.sisaAngka < 6,
            'bg-yellow-300': data.sisaAngka < 11,
            'font-medium': data
        };
    };

    const actionTemplate = (data) => {
        return (
            <div>
                <div>
                    <Button icon="pi pi-user-edit" label="Edit" style={{ fontSize: '14px', textAlign: 'center', background: '#f45700ed', height: '30px', width: '100%', marginBottom: '8px', border: '0px' }} onClick={() => modalDialog('popupEdit', data)} />
                </div>
                <div>
                    <Button icon="pi pi-trash" label="Hapus" style={{ fontSize: '14px', textAlign: 'center', background: '#d40c0c', height: '30px', width: '100%', border: '0px' }} onClick={() => confirmDelete(data)} />
                </div>
            </div>
        );
    }

    const popupFoot = (name) => {
        return (
            <div>
                <Button label="Batal" style={{ background: '#d40c0c', color: "white", border: '0px' }} onClick={() => setPopupDialog(false)} icon="pi pi-times" autoFocus />
                <Button label="Simpan" style={{ border: '0px' }} onClick={() => onClickSave(name)} icon="pi pi-save" autoFocus />
            </div>
        )
    }

    if (!authenticated){
        return (
            !waitAuthenticated ?
            <></> :
            <Navigate replace to="/login" />
        )
    }else{
        return (
            <>
                <Header />
                <div className="body">
                    <Panel header='Tahanan'>
                        <DataTable showGridlines value={listDataTable} size='small' header={header} rowClassName={rowClass}
                        paginator lazy first={first} rows={rows} totalRecords={totalData} rowsPerPageOptions={[5, 10, 25]} onPage={onPageChange}>
                            <Column field="namaTahanan" header="NAMA TAHANAN" style={{ textAlign: 'center' }}></Column>
                            <Column field="tglMasuk" header="TANGGAL MASUK" style={{ textAlign: 'center' }}></Column>
                            <Column field="tglKeluar" header="TANGGAL KELUAR" style={{ textAlign: 'center' }}></Column>
                            <Column field="sisaHari" header="SISA HARI" style={{ textAlign: 'center' }}></Column>
                            <Column field="data" header="AKSI" body={actionTemplate} headerStyle={{ width: '10%' }}></Column>
                        </DataTable>
                    </Panel>
                    <Dialog header={statusFlag === 'popupEdit' ? 'Edit Tahanan' : 'Tambah Tahanan'} visible={popupDialog} style={{ width: '50vw' }} onHide={() => setPopupDialog(false)} footer={popupFoot(statusFlag)}>
                        <div className="field" style={{width: '50%', margin: '30px'}}>
                            <label for="namaTahanan">Nama Tahanan</label>
                            <div>
                                <InputText id='namaTahanan' value={namaTahanan} onChange={(e) => setNamaTahanan(e.target.value)} style={{width: '100%'}} />
                                <small></small>
                            </div>
                        </div>
                        <div className="field" style={{width: '50%', margin: '30px'}}>
                            <label for="tglMasuk">Tanggal Masuk</label>
                            <div>
                                <Calendar value={tglMasuk} onChange={(e) => setTglMasuk(e.value)} showIcon dateFormat="dd/mm/yy" />
                                <small></small>
                            </div>
                        </div>
                        <div className="field" style={{width: '50%', margin: '30px'}}>
                            <label for="tglKeluar">Tanggal Keluar</label>
                            <div>
                                <Calendar value={tglKeluar} onChange={(e) => setTglKeluar(e.value)} showIcon dateFormat="dd/mm/yy" />
                                <small></small>
                            </div>
                        </div>
                    </Dialog>
                    <ConfirmDialog />
                </div>
                <Footer />
                <Toast ref={toast} />
                <Loading loading={isLoading} />
            </>
        );
    }
}