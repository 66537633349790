import axios from "axios";
import * as UrlPath from "../urlPath";

const baseUrl = process.env.REACT_APP_BACKEND;

export const getVideo = async (filter) => {
  const param = {
    search: filter.search,
    limit: filter.rows,
    offset: filter.first
  }

  try {
    const config = {
      method: "POST",
      data: param,
      url: baseUrl + UrlPath.URL_GET_VIDEO,
      headers: {'x-access-token': localStorage.getItem("token")}
    };
    const { data } = await axios(config);
    return data;
  } catch (err) {
    const { data } = (await err.response) ? err.response : "";
    return data;
  }
};

export const getVideoPlay = async () => {
  try {
    const config = {
      method: "GET",
      url: baseUrl + UrlPath.URL_GET_VIDEO_PLAY,
      headers: {'x-access-token': localStorage.getItem("token")}
    };
    const { data } = await axios(config);
    return data;
  } catch (err) {
    const { data } = (await err.response) ? err.response : "";
    return data;
  }
};

export const createVideo = async (value) => {
  const param = {
    judul: value.judul,
    deskripsi: value.deskripsi,
    url: value.url,
    active: value.active,
    pembuat: localStorage.getItem("userName")
  }

  try {
    const config = {
      method: "POST",
      data: param,
      url: baseUrl + UrlPath.URL_CREATE_VIDEO,
      headers: {'x-access-token': localStorage.getItem("token")}
    };
    const { data } = await axios(config);
    return data;
  } catch (err) {
    const { data } = (await err.response) ? err.response : "";
    return data;
  }
};

export const editVideo = async (value) => {
  const param = {
    id: value.id,
    judul: value.judul,
    deskripsi: value.deskripsi,
    url: value.url,
    active: value.active,
    editor: localStorage.getItem("userName")
  }

  try {
    const config = {
      method: "POST",
      data: param,
      url: baseUrl + UrlPath.URL_EDIT_VIDEO,
      headers: {'x-access-token': localStorage.getItem("token")}
    };
    const { data } = await axios(config);
    return data;
  } catch (err) {
    const { data } = (await err.response) ? err.response : "";
    return data;
  }
};

export const deleteVideo = async (value) => {
  const param = {
    id: value
  }

  try {
    const config = {
      method: "POST",
      data: param,
      url: baseUrl + UrlPath.URL_DELETE_VIDEO,
      headers: {'x-access-token': localStorage.getItem("token")}
    };
    const { data } = await axios(config);
    return data;
  } catch (err) {
    const { data } = (await err.response) ? err.response : "";
    return data;
  }
};