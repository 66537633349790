import { Menubar } from 'primereact/menubar';

export const Header = (props) => {
    let items = [];
    const roleUserLogin = localStorage.getItem("userRoleId");
    if(roleUserLogin === '1' || roleUserLogin === '2'){
        items = [
            {
                label: 'Beranda',
                icon: 'pi pi-fw pi-home',
                url: 'https://ranmortangerang.com/'
            },
            {
                label: 'Play',
                icon: 'pi pi-fw pi-play',
                url: 'https://ranmortangerang.com/play'
            },
            {
                label: 'Tahanan',
                icon: 'pi pi-fw pi-calendar',
                url: 'https://ranmortangerang.com/tahanan'
            },
            {
                label: 'Video',
                icon: 'pi pi-fw pi-video',
                url: 'https://ranmortangerang.com/video'
            },
            {
                label: 'Progress',
                icon: 'pi pi-fw pi-check-square',
                url: 'https://ranmortangerang.com/progress'
            },
            {
                label: 'Users',
                icon: 'pi pi-fw pi-user',
                url: 'https://ranmortangerang.com/users'
            },
            {
                label: 'Logout',
                icon: 'pi pi-fw pi-sign-out',
                url: 'https://ranmortangerang.com/logout'
            }
        ];
    } else if(roleUserLogin === '3'){
        items = [
            {
                label: 'Beranda',
                icon: 'pi pi-fw pi-home',
                url: 'https://ranmortangerang.com/'
            },
            {
                label: 'Play',
                icon: 'pi pi-fw pi-play',
                url: 'https://ranmortangerang.com/play'
            },
            {
                label: 'Tahanan',
                icon: 'pi pi-fw pi-calendar',
                url: 'https://ranmortangerang.com/tahanan'
            },
            {
                label: 'Video',
                icon: 'pi pi-fw pi-video',
                url: 'https://ranmortangerang.com/video'
            },
            {
                label: 'Progress',
                icon: 'pi pi-fw pi-check-square',
                url: 'https://ranmortangerang.com/progress'
            },
            {
                label: 'Logout',
                icon: 'pi pi-fw pi-sign-out',
                url: 'https://ranmortangerang.com/logout'
            }
        ];
    } else {
        items = [
            {
                label: 'Beranda',
                icon: 'pi pi-fw pi-home',
                url: 'https://ranmortangerang.com/'
            },
            {
                label: 'Play',
                icon: 'pi pi-fw pi-play',
                url: 'https://ranmortangerang.com/play'
            },
            {
                label: 'Logout',
                icon: 'pi pi-fw pi-sign-out',
                url: 'https://ranmortangerang.com/logout'
            }
        ];
    }

    const end = <div style={{color: '#DDE6ED', paddingRight: '25px'}}>Halo <b style={{color: '#DDE6ED'}}>{localStorage.getItem('userName')}</b>, anda masuk sebagai <b style={{color: '#DDE6ED'}}>{localStorage.getItem('userRole')}</b></div>;

    return (
        <Menubar model={items} end={end} style={{height: '50px', backgroundColor: '#526D82', border: '0px', borderRadius: '0px', padding: '0px'}} />
    );
}

