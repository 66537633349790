import axios from "axios";
import * as UrlPath from "../urlPath";

const baseUrl = process.env.REACT_APP_BACKEND;

export const getTahanan = async (filter) => {
  const param = {
    search: filter.search,
    limit: filter.rows,
    offset: filter.first
  }

  try {
    const config = {
      method: "POST",
      data: param,
      url: baseUrl + UrlPath.URL_GET_TAHANAN,
      headers: {'x-access-token': localStorage.getItem("token")}
    };
    const { data } = await axios(config);
    return data;
  } catch (err) {
    const { data } = (await err.response) ? err.response : "";
    return data;
  }
};

export const getTahananPlay = async () => {
    try {
      const config = {
        method: "GET",
        url: baseUrl + UrlPath.URL_GET_TAHANAN_PLAY,
        headers: {'x-access-token': localStorage.getItem("token")}
      };
      const { data } = await axios(config);
      return data;
    } catch (err) {
      const { data } = (await err.response) ? err.response : "";
      return data;
    }
};

export const getTahananBeranda = async (filter) => {
  const param = {
    limit: filter.rows,
    offset: filter.first
  }

  try {
    const config = {
      method: "POST",
      data: param,
      url: baseUrl + UrlPath.URL_GET_TAHANAN_BERANDA,
      headers: {'x-access-token': localStorage.getItem("token")}
    };
    const { data } = await axios(config);
    return data;
  } catch (err) {
    const { data } = (await err.response) ? err.response : "";
    return data;
  }
};

export const createTahanan = async (value) => {
  const param = {
    namaTahanan: value.namaTahanan,
    tglMasuk: value.tglMasuk,
    tglKeluar: value.tglKeluar,
    pembuat: localStorage.getItem("userName")
  }

  try {
    const config = {
      method: "POST",
      data: param,
      url: baseUrl + UrlPath.URL_CREATE_TAHANAN,
      headers: {'x-access-token': localStorage.getItem("token")}
    };
    const { data } = await axios(config);
    return data;
  } catch (err) {
    const { data } = (await err.response) ? err.response : "";
    return data;
  }
};

export const editTahanan = async (value) => {
  const param = {
    id: value.id,
    namaTahanan: value.namaTahanan,
    tglMasuk: value.tglMasuk,
    tglKeluar: value.tglKeluar,
    editor: localStorage.getItem("userName")
  }

  try {
    const config = {
      method: "POST",
      data: param,
      url: baseUrl + UrlPath.URL_EDIT_TAHANAN,
      headers: {'x-access-token': localStorage.getItem("token")}
    };
    const { data } = await axios(config);
    return data;
  } catch (err) {
    const { data } = (await err.response) ? err.response : "";
    return data;
  }
};

export const deleteTahanan = async (value) => {
  const param = {
    id: value
  }

  try {
    const config = {
      method: "POST",
      data: param,
      url: baseUrl + UrlPath.URL_DELETE_TAHANAN,
      headers: {'x-access-token': localStorage.getItem("token")}
    };
    const { data } = await axios(config);
    return data;
  } catch (err) {
    const { data } = (await err.response) ? err.response : "";
    return data;
  }
};