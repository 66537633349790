import { Panel } from "primereact/panel";
import { Header } from '../component/header';
import { Footer } from '../component/footer';
import { Loading } from "../component/loading";
import { useEffect, useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { Navigate } from "react-router-dom";
import { getUsers, getRoles, createUser, editUser, deleteUser } from "../service/Users";
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';

export const Users = (props) => {
    
    const [authenticated, setAuthenticated] = useState(null);
    const [waitAuthenticated, setWaitAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const toast = useRef(null);
    
    const [globalFilter, setGlobalFilter] = useState('');
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalData, setTotalData] = useState(0);

    const [popupDialog, setPopupDialog] = useState(false);
    const [statusFlag, setStatusFlag] = useState('');
    const [id, setId] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [roleId, setRoleId] = useState('');
    const [changePass, setChangePass] = useState(false);

    const [listDataTable, setListDataTable] = useState([]);
    const [listRoles, setListRoles] = useState([]);
    
    const roleUserLogin = localStorage.getItem("userRoleId");

    const fetchData = async (filter) => {
        setIsLoading(true);
        try {
            let listUsers = await getUsers(filter);
            if (listUsers.response_code !== "00") {
                throw listUsers;
            }
            setListDataTable(listUsers.data);
            setTotalData(listUsers.totalData);
        } catch (error) {
            if (error.response_code === 'ET'){
                localStorage.setItem("tokenExpired", true);
                setAuthenticated(false);
                return;
            } else {
                toast.current.show({ severity: 'error', summary: 'Gagal Mendapatkan Data User', detail: error.response_message, life: 2000 });
                setListDataTable([]);
                setTotalData(0);
            }
        }

        try {
            if (listRoles.length === 0){
                let listRoles = await getRoles();
                if (listRoles.response_code !== "00") {
                    throw listRoles;
                }
                setListRoles(listRoles.data);
            }
        } catch (error) {
            if (error.response_code === 'ET'){
                localStorage.setItem("tokenExpired", true);
                setAuthenticated(false);
                return;
            } else {
                toast.current.show({ severity: 'error', summary: 'Gagal Mendapatkan Data Role', detail: error.response_message, life: 2000 });
                setListRoles([]);
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (!authenticated) {
            const loggedInUser = localStorage.getItem("authenticated");
            if (loggedInUser) {
                setAuthenticated(loggedInUser);
                setWaitAuthenticated(true);
                const filter = {
                    search: globalFilter,
                    first: first,
                    rows: rows
                }
                fetchData(filter);
            }else{
                setWaitAuthenticated(true);
            }
        }else{
            const filter = {
                search: globalFilter,
                first: first,
                rows: rows
            }
            fetchData(filter);
        }
    }, [refresh]);

    const modalDialog = (name, data) => {
        setStatusFlag(name);
        if(name === 'popupEdit'){
            setId(data.id);
            setUserName(data.userName);
            setPassword('');
            setRoleId(data.roleId);
            setChangePass(false);
        } else {
            setId('');
            setUserName('');
            setPassword('');
            setRoleId('');
        }

        setPopupDialog(true);
    }

    const onClickDelete = async (data) => {
        try {
            setIsLoading(true)
            const value = {
                id: data.id,
                roleId: data.roleId
            }
            let deleteDataUser = await deleteUser(value);
            if (deleteDataUser.response_code !== "00") {
                throw deleteDataUser;
            }
            toast.current.show({ severity: 'success', summary: 'Hapus User Berhasil', detail: 'User berhasil dihapus', life: 2000 });
        } catch (error) {
            if (error.response_code === 'ET'){
                localStorage.setItem("tokenExpired", true);
                setAuthenticated(false);
                return;
            } else {
                toast.current.show({ severity: 'error', summary: 'Gagal Menghapus User', detail: error.response_message, life: 2000 });
            }
        }
        setRefresh(refresh+1);
    }

    const reject = () => {
    }

    const confirmDelete = (data) => {
        confirmDialog({
            message: 'Apakah anda yakin mau menghapus user ini?',
            header: 'Hapus User',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Hapus',
            rejectLabel: 'Batal',
            accept: () => onClickDelete(data),
            reject: reject
        });
    };

    const onClickSave = (name) => {
        if(name === 'popupEdit'){
            if (changePass){
                if (roleId && password) {
                    saveData(name);
                }
            } else {
                if (roleId) {
                    saveData(name);
                }
            }
        } else {
            if (userName && roleId && password) {
                saveData(name);
            }
        }
    }

    const saveData = async (name) => {
        if (name === 'popupEdit'){
            try {
                setIsLoading(true);
                const data = {
                    id: id,
                    roleId: roleId,
                    password: password,
                    changePass: changePass
                }

                let editDataUser = await editUser(data);
                if (editDataUser.response_code !== "00") {
                    throw editDataUser;
                }
                toast.current.show({ severity: 'success', summary: 'Edit User Berhasil', detail: 'User berhasil edit', life: 2000 });
            } catch (error) {
                if (error.response_code === 'ET'){
                    localStorage.setItem("tokenExpired", true);
                    setAuthenticated(false);
                    return;
                } else {
                    toast.current.show({ severity: 'error', summary: 'Gagal Mengedit User', detail: error.response_message, life: 2000 });
                }
            }
        } else {
            try {
                setIsLoading(true);
                const data = {
                    userName: userName,
                    password: password,
                    roleId: roleId
                }

                let createDataUser = await createUser(data);
                if (createDataUser.response_code !== "00") {
                    throw createDataUser;
                }
                toast.current.show({ severity: 'success', summary: 'Tambah User Berhasil', detail: 'User berhasil ditambahkan', life: 2000 });
            } catch (error) {
                if (error.response_code === 'ET'){
                    localStorage.setItem("tokenExpired", true);
                    setAuthenticated(false);
                    return;
                } else {
                    toast.current.show({ severity: 'error', summary: 'Gagal Menambahkan User', detail: error.response_message, life: 2000 });
                }
            }
        }
        setPopupDialog(false);
        setRefresh(refresh+1);
    }

    const onCheckChangePass = (value) => {
        setChangePass(value);
        setPassword('');
    }

    useEffect(() => {
        if (authenticated) {
            const delaySearch = setTimeout(async () => {
                const filter = {
                    search: globalFilter,
                    first: first,
                    rows: rows
                }
                fetchData(filter);
            }, 1000);
    
            return () => clearTimeout(delaySearch);
        }
    }, [globalFilter])

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);

        const filter = {
            search: globalFilter,
            first: event.first,
            rows: event.rows
        }
        fetchData(filter)
    };

    let header = (
        <div className="grid">
            <div className="col-12" style={{ padding: "0px" }}>
                <div className="field grid" style={{ padding: "15px 10px 5px 10px", margin: '0px' }}>
                    <div className="col-6">
                        <Button label="Tambah" style={{fontSize: '14px', height: '40px'}} icon="pi pi-plus" onClick={() => modalDialog('popupAdd')}></Button>
                    </div>
                    <div className="col-6" style={{ textAlign: "right" }}>
                        <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                        <InputText type="search" style={{fontSize: '14px', height: '40px'}} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search" />
                    </div>
                </div>
            </div>
        </div>
    );

    const actionTemplate = (data) => {
        const buttonDisabled = !(roleUserLogin === '1') && (data.roleId === 1 || data.roleId === 2);
        return (
            <div>
                <div>
                    <Button icon="pi pi-user-edit" label="Edit" disabled={buttonDisabled} onClick={() => modalDialog('popupEdit', data)}
                    style={ buttonDisabled ? { fontSize: '14px', textAlign: 'center', background: '#FFFFFF', height: '30px', width: '100%', marginBottom: '8px', border: '0px' } : { fontSize: '14px', textAlign: 'center', background: '#f45700ed', height: '30px', width: '100%', marginBottom: '8px', border: '0px' }} />
                </div>
                <div>
                    <Button icon="pi pi-trash" label="Hapus" disabled={buttonDisabled} onClick={() => confirmDelete(data)}
                    style={ buttonDisabled ? {fontSize: '14px', textAlign: 'center', background: '#FFFFFF', height: '30px', width: '100%', border: '0px'} : {fontSize: '14px', textAlign: 'center', background: '#d40c0c', height: '30px', width: '100%', border: '0px'}} />
                </div>
            </div>
        );
    }

    const popupFoot = (name) => {
        return (
            <div>
                <Button label="Batal" style={{ background: '#d40c0c', color: "white", border: '0px' }} onClick={() => setPopupDialog(false)} icon="pi pi-times" autoFocus />
                <Button label="Simpan" style={{ border: '0px' }} onClick={() => onClickSave(name)} icon="pi pi-save" autoFocus />
            </div>
        )
    }

    if (!authenticated){
        return (
            !waitAuthenticated ?
            <></> :
            <Navigate replace to="/login" />
        )
    }else{
        return (
            <>
                <Header />
                <div className="body">
                    <Panel header='Users'>
                        <DataTable showGridlines value={listDataTable} size='small' header={header}
                        paginator lazy first={first} rows={rows} totalRecords={totalData} rowsPerPageOptions={[5, 10, 25]} onPage={onPageChange} >
                            <Column field="userName" header="USERNAME" style={{ textAlign: 'center' }}></Column>
                            <Column field="tglBuat" header="TANGGAL BUAT" style={{ textAlign: 'center' }}></Column>
                            <Column field="roleName" header="ROLE" style={{ textAlign: 'center' }}></Column>
                            <Column field="pembuat" header="PEMBUAT" style={{ textAlign: 'center' }}></Column>
                            <Column field="data" header="AKSI" body={actionTemplate} headerStyle={{ width: '10%' }}></Column>
                        </DataTable>
                    </Panel>
                    <Dialog header={statusFlag === 'popupEdit' ? 'Edit User' : 'Tambah User'} visible={popupDialog} style={{ width: '50vw' }} onHide={() => setPopupDialog(false)} footer={popupFoot(statusFlag)}>
                        <div className="field" style={{width: '50%', margin: '30px'}}>
                            <label for="userName">Username</label>
                            <div>
                                <InputText id='userName' value={userName} onChange={(e) => setUserName(e.target.value)} style={statusFlag === 'popupEdit' ? {backgroundColor: '#999999', width: '100%'} : {width: '100%'}} autoComplete="off" disabled={statusFlag === 'popupEdit' ? true : false} />
                                <small style={{color: '#777777'}}>Username tidak dapat diubah setelah dibuat</small>
                            </div>
                        </div>
                        <div className="field" style={{ width: '50%', margin: '30px'}}>
                            <label for="password">Password</label>
                            {statusFlag === 'popupEdit' ?
                            <div>
                                <Password id='password' value={password} onChange={(e) => setPassword(e.target.value)} style={{width: '100%'}} inputStyle={changePass ? {backgroundColor: '#FFFFFF'} : {backgroundColor: '#999999'}} feedback={false} toggleMask disabled={!changePass} />
                                <div className="flex align-items-center" style={{marginTop: '10px'}}>
                                    <Checkbox inputId="changePass" onChange={e => onCheckChangePass(e.checked)} checked={changePass} />
                                    <label htmlFor="changePass" className="ml-2" style={{color: '#777777'}}>Centang untuk mengubah password</label>
                                </div>
                            </div> : 
                            <Password id='password' value={password} onChange={(e) => setPassword(e.target.value)} style={{width: '100%'}} feedback={false} toggleMask />}
                        </div>
                        <div className="field" style={{width: '50%', margin: '30px'}}>
                            <label for="roleName">Role</label>
                            <div>
                                {/* <InputText id='roleName' value={roleId} onChange={(e) => setRoleId(e.target.value)} style={{width: '100%'}} autoComplete="off" /> */}
                                <Dropdown value={roleId} onChange={(e) => setRoleId(e.value)} options={listRoles} optionLabel="roleName" optionValue="id" placeholder="Pilih role user" style={{width: '100%'}} />
                                <small></small>
                            </div>
                        </div>
                    </Dialog>
                    <ConfirmDialog />
                </div>
                <Footer />
                <Toast ref={toast} />
                <Loading loading={isLoading} />
            </>
        );
    }
}