import axios from "axios";
import * as UrlPath from "../urlPath";

const baseUrl = process.env.REACT_APP_BACKEND;

export const onLogin = async (data) => {
  const param = {
    userName: data.userName,
    password:data.password
  };
  
  try {
    const config = {
      method: "POST",
      data: param,
      url: baseUrl + UrlPath.URL_USER_LOGIN
    };
    const { data } = await axios(config);
    return data;
  } catch (err) {
    const { data } = (await err.response) ? err.response : "";
    return data;
  }
};

export const getUsers = async (filter) => {
  const param = {
    search: filter.search,
    limit: filter.rows,
    offset: filter.first
  }

  try {
    const config = {
      method: "POST",
      data: param,
      url: baseUrl + UrlPath.URL_GET_USERS,
      headers: {'x-access-token': localStorage.getItem("token")}
    };
    const { data } = await axios(config);
    return data;
  } catch (err) {
    const { data } = (await err.response) ? err.response : "";
    return data;
  }
};

export const getRoles = async () => {
  try {
    const config = {
      method: "GET",
      url: baseUrl + UrlPath.URL_GET_ROLES,
      headers: {'x-access-token': localStorage.getItem("token")}
    };
    const { data } = await axios(config);
    return data;
  } catch (err) {
    const { data } = (await err.response) ? err.response : "";
    return data;
  }
};

export const createUser = async (value) => {
  const param = {
    roleId: value.roleId,
    userName: value.userName,
    password: value.password,
    pembuat: localStorage.getItem("userName")
  }

  try {
    const config = {
      method: "POST",
      data: param,
      url: baseUrl + UrlPath.URL_CREATE_USER,
      headers: {'x-access-token': localStorage.getItem("token")}
    };
    const { data } = await axios(config);
    return data;
  } catch (err) {
    const { data } = (await err.response) ? err.response : "";
    return data;
  }
};

export const editUser = async (value) => {
  const param = {
    id: value.id,
    roleId: value.roleId,
    password: value.password,
    changePass: value.changePass,
    editor: localStorage.getItem("userName")
  }

  try {
    const config = {
      method: "POST",
      data: param,
      url: baseUrl + UrlPath.URL_EDIT_USER,
      headers: {'x-access-token': localStorage.getItem("token")}
    };
    const { data } = await axios(config);
    return data;
  } catch (err) {
    const { data } = (await err.response) ? err.response : "";
    return data;
  }
};

export const deleteUser = async (value) => {
  const param = {
    id: value.id,
    roleId: value.roleId
  }

  try {
    const config = {
      method: "POST",
      data: param,
      url: baseUrl + UrlPath.URL_DELETE_USER,
      headers: {'x-access-token': localStorage.getItem("token")}
    };
    const { data } = await axios(config);
    return data;
  } catch (err) {
    const { data } = (await err.response) ? err.response : "";
    return data;
  }
};