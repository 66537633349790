
export const Footer = (props) => {

    return (
        <div id="footer">

        </div>
    );
}

