import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from 'react';
import { Loading } from "../component/loading";
import { onLogin } from "../service/Users";
import { Toast } from 'primereact/toast';

export const Login = (props) => {

    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        const loggedInUser = localStorage.getItem("authenticated");
        const tokenExpired = localStorage.getItem("tokenExpired");
        if (loggedInUser) {
            if (tokenExpired){
                localStorage.clear();
                toast.current.show({ severity: 'warn', summary: 'Token Habis', detail: 'Token anda sudah habis, silahkan melakukan login ulang', life: 5000 });
            } else {
                navigate('/');
            }
        }
    }, []);

    const onClickLogin = async () => {
        try {
            setIsLoading(true)
            const data = {
                userName: userName,
                password: password
            }
            let login = await onLogin(data);
            if (login.response_code !== "00") {
                throw login;
            }
            if (login.login) {
                setIsLoading(false)
                localStorage.setItem("authenticated", true);
				localStorage.setItem('token', login.token);
                localStorage.setItem('userName', login.data.userName);
                localStorage.setItem('userRoleId', login.data.userRoleId);
                localStorage.setItem('userRole', login.data.userRole);
                navigate('/');
            }else{
                toast.current.show({ severity: 'error', summary: 'Login Gagal', detail: login.response_message, life: 2000 });
                setIsLoading(false)
            }

        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Login Gagal', detail: error.response_message, life: 2000 });
            setIsLoading(false)
        }
    }

    return (
        <div id="loginBody">
            <div id="loginPanel">
                <div style={{margin: '0px auto 50px auto', textAlign: 'center'}}>
                    <label style={{fontSize: '30px'}}>User Login</label>
                </div>
                <div className="field grid" style={{width: '60%', margin: '30px auto'}}>
                    <label className='col-3' for="username">Username</label>
                    <div className='col-9'>
                        <InputText id='username' value={userName} onChange={(e) => setUserName(e.target.value)} style={{width: '100%'}} autoComplete="off" />
                        <small></small>
                    </div>
                </div>
                <div className="field grid" style={{width: '60%', margin: '30px auto'}}>
                    <label className='col-3' for="password">Password</label>
                    <div className='col-9'>
                        <Password id='password' value={password} onChange={(e) => setPassword(e.target.value)} style={{width: '100%'}} feedback={false} toggleMask />
                        <small></small>
                    </div>
                </div>
                <div className="field grid" style={{width: '50%', margin: '40px auto', justifyContent: 'center'}}>
                    <Button label="Login" style={{ width: '150px', border: '0px' }} onClick={() => onClickLogin()} autoFocus />
                </div>
            </div>
            <Loading loading={isLoading} />
            <Toast ref={toast} position="top-center" />
        </div>
    );
}

