// Users
export const URL_USER_LOGIN = "/user-login";
export const URL_GET_USERS = "/get-users";
export const URL_GET_ROLES = "/get-roles";
export const URL_CREATE_USER = "/create-user";
export const URL_EDIT_USER = "/edit-user";
export const URL_DELETE_USER = "/delete-user";

// Video
export const URL_GET_VIDEO = "/get-video";
export const URL_GET_VIDEO_PLAY = "/get-video-play";
export const URL_CREATE_VIDEO = "/create-video";
export const URL_EDIT_VIDEO = "/edit-video";
export const URL_DELETE_VIDEO = "/delete-video";

// Tahanan
export const URL_GET_TAHANAN = "/get-tahanan";
export const URL_GET_TAHANAN_BERANDA = "/get-tahanan-beranda";
export const URL_GET_TAHANAN_PLAY = "/get-tahanan-play";
export const URL_CREATE_TAHANAN = "/create-tahanan";
export const URL_EDIT_TAHANAN = "/edit-tahanan";
export const URL_DELETE_TAHANAN = "/delete-tahanan";

// Progress
export const URL_GET_PROGRESS = "/get-progress";
export const URL_CREATE_PROGRESS = "/create-progress";
export const URL_EDIT_PROGRESS = "/edit-progress";
export const URL_DELETE_PROGRESS = "/delete-progress";