import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { Login } from './pages/login.js';
import { Home } from './pages/home.js';
import { Play } from './pages/play.js';
import { Tahanan } from './pages/tahanan.js';
import { Video } from './pages/video.js';
import { Progress } from './pages/progress.js';
import { Users } from './pages/users.js';
import { Logout } from './pages/logout.js';

const RoutesJSX = () => {
  return (
    <Router>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/' element={<Home />} />
        <Route path='/play' element={<Play />} />
        <Route path='/tahanan' element={<Tahanan />} />
        <Route path='/video' element={<Video />} />
        <Route path='/progress' element={<Progress />} />
        <Route path='/users' element={<Users />} />
        <Route path='/logout' element={<Logout />} />
      </Routes>
    </Router>
  );
}

export default RoutesJSX;
