import { Header } from '../component/header';
import { Footer } from '../component/footer';
import { Chart } from 'primereact/chart';
import { Loading } from "../component/loading";
import { useEffect, useState, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Panel } from 'primereact/panel';
import { Navigate } from "react-router-dom";
import { getTahananBeranda } from "../service/Tahanan";
        

export const Home = (props) => {

    const [authenticated, setAuthenticated] = useState(null);
    const [waitAuthenticated, setWaitAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef(null);
    
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalData, setTotalData] = useState(0);

    const [chartData, setChartData] = useState({});
    const [listDataTable, setListDataTable] = useState([]);

    const fetchData = async (filter) => {
        setIsLoading(true);
        try {
            let listTahananBeranda = await getTahananBeranda(filter);
            if (listTahananBeranda.response_code !== "00") {
                throw listTahananBeranda;
            }
            setListDataTable(listTahananBeranda.data);
            setTotalData(listTahananBeranda.totalData);

            const dataChart = {
                labels: ['Lebih dari 10 sisa hari', '6-10 sisa hari', '0-5 sisa hari'],
                datasets: [
                    {
                        label: 'Jumlah Tahanan',
                        data: [
                            listTahananBeranda.chartData.green,
                            listTahananBeranda.chartData.yellow,
                            listTahananBeranda.chartData.red
                        ],
                        backgroundColor: [
                            '#70B595',
                            '#FFD965',
                            '#E9828C'
                        ]
                    }
                ]
            }
            
            setChartData(dataChart);
        } catch (error) {
            if (error.response_code === 'ET'){
                localStorage.setItem("tokenExpired", true);
                setAuthenticated(false);
                return;
            } else {
                toast.current.show({ severity: 'error', summary: 'Gagal Mendapatkan Data Tahanan', detail: error.response_message, life: 2000 });
                setListDataTable([]);
                setTotalData(0);
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (!authenticated) {
            const loggedInUser = localStorage.getItem("authenticated");
            if (loggedInUser) {
                setAuthenticated(loggedInUser);
                setWaitAuthenticated(true);
                const filter = {
                    first: first,
                    rows: rows
                }
                fetchData(filter);
            }else{
                setWaitAuthenticated(true);
            }
        }else{
            const filter = {
                first: first,
                rows: rows
            }
            fetchData(filter);
        }
    }, []);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);

        const filter = {
            first: event.first,
            rows: event.rows
        }
        fetchData(filter)
    };

    const rowClass = (data) => {
        return {
            'bg-red-300': data.sisaAngka < 6,
            'bg-yellow-300': data.sisaAngka < 11,
            'font-medium': data
        };
    };

    if (!authenticated){
        return (
            !waitAuthenticated ?
            <></> :
            <Navigate replace to="/login" />
        )
    }else{
        return (
            <>
                <Header />
                <div className="body">
                    <Panel header='Beranda'>
                        <div className='grid' style={{marginBottom: '25px'}}>
                            <div className='col-6 flex justify-content-center flex-wrap'>
                                <Chart type="bar" data={chartData} style={{width: '500px'}}/>
                            </div>
                            <div className='col-6 flex justify-content-center flex-wrap'>
                                <Chart type="pie" data={chartData} style={{width: '250px'}}/>
                            </div>
                        </div>
                        <DataTable showGridlines value={listDataTable} size='small' rowClassName={rowClass}
                        paginator lazy first={first} rows={rows} totalRecords={totalData} rowsPerPageOptions={[5, 10, 25]} onPage={onPageChange}>
                            <Column field="namaTahanan" header="NAMA TAHANAN" style={{ textAlign: 'center' }}></Column>
                            <Column field="tglMasuk" header="TANGGAL MASUK" style={{ textAlign: 'center' }}></Column>
                            <Column field="tglKeluar" header="TANGGAL KELUAR" style={{ textAlign: 'center' }}></Column>
                            <Column field="sisaHari" header="SISA HARI" style={{ textAlign: 'center' }}></Column>
                        </DataTable>
                    </Panel>
                </div>
                <Footer />
                <Toast ref={toast} />
                <Loading loading={isLoading} />
            </>
        );
    }
}

