import './App.css';
import './Style.css'
import RoutesJSX from "./RoutesJSX";
import "primereact/resources/themes/lara-light-indigo/theme.css"; 
import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';


function App() {
  return (
    <div id='app'>
      <RoutesJSX />
    </div>
  );
}

export default App;
