import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

export const Logout = (props) => {
    
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.clear();
        navigate('/login');
    }, []);
}