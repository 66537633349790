import axios from "axios";
import * as UrlPath from "../urlPath";

const baseUrl = process.env.REACT_APP_BACKEND;

export const getProgress = async (filter) => {
  const param = {
    search: filter.search,
    limit: filter.rows,
    offset: filter.first
  }

  try {
    const config = {
      method: "POST",
      data: param,
      url: baseUrl + UrlPath.URL_GET_PROGRESS,
      headers: {'x-access-token': localStorage.getItem("token")}
    };
    const { data } = await axios(config);
    return data;
  } catch (err) {
    const { data } = (await err.response) ? err.response : "";
    return data;
  }
};

export const createProgress = async (value) => {
    const param = {
      noLaporan: value.noLaporan,
      uraian: value.uraian,
      status: value.status,
      pembuat: localStorage.getItem("userName")
    }
  
    try {
      const config = {
        method: "POST",
        data: param,
        url: baseUrl + UrlPath.URL_CREATE_PROGRESS,
        headers: {'x-access-token': localStorage.getItem("token")}
      };
      const { data } = await axios(config);
      return data;
    } catch (err) {
      const { data } = (await err.response) ? err.response : "";
      return data;
    }
};

export const editProgress = async (value) => {
    const param = {
      id: value.id,
      idBulan: value.idBulan,
      bulan: value.bulan,
      fieldEdit: value.fieldEdit,
      valueEdit: value.valueEdit,
      editor: localStorage.getItem("userName")
    }
  
    try {
      const config = {
        method: "POST",
        data: param,
        url: baseUrl + UrlPath.URL_EDIT_PROGRESS,
        headers: {'x-access-token': localStorage.getItem("token")}
      };
      const { data } = await axios(config);
      return data;
    } catch (err) {
      const { data } = (await err.response) ? err.response : "";
      return data;
    }
};

export const deleteProgress = async (value) => {
    const param = {
      id: value
    }
  
    try {
      const config = {
        method: "POST",
        data: param,
        url: baseUrl + UrlPath.URL_DELETE_PROGRESS,
        headers: {'x-access-token': localStorage.getItem("token")}
      };
      const { data } = await axios(config);
      return data;
    } catch (err) {
      const { data } = (await err.response) ? err.response : "";
      return data;
    }
};