import { Panel } from "primereact/panel";
import { Header } from '../component/header';
import { Footer } from '../component/footer';
import { Loading } from "../component/loading";
import { useEffect, useState, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { Navigate } from "react-router-dom"
import { Row } from "primereact/row";
import { getProgress, createProgress, editProgress, deleteProgress } from "../service/Progress";
        

export const Progress = (props) => {
    
    const [authenticated, setAuthenticated] = useState(null);
    const [waitAuthenticated, setWaitAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const toast = useRef(null);

    const [globalFilter, setGlobalFilter] = useState('');
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5);
    const [totalData, setTotalData] = useState(0);

    const [tambahDialog, setTambahDialog] = useState(false);
    const [id, setId] = useState('');
    const [noLaporan, setNoLaporan] = useState('');
    const [uraianKejadian, setUraianKejadian] = useState('');
    const [status, setStatus] = useState('');
    const [isTextArea, setIsTextArea] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [bulan, setBulan] = useState('');
    const [idBulan, setIdBulan] = useState('');
    const [fieldEdit, setFieldEdit] = useState('');
    const [valueEdit, setValueEdit] = useState('');
    const [titleEdit, setTitleEdit] = useState('');
    
    const [selectedCell, setSelectedCell] = useState('');
    
    const [listDataTable, setListDataTable] = useState([]);

    const fetchData = async (filter) => {
        setIsLoading(true);
        try {
            let listProgress = await getProgress(filter);
            if (listProgress.response_code !== "00") {
                throw listProgress;
            }
            setListDataTable(listProgress.data);
            setTotalData(listProgress.totalData * 6);
        } catch (error) {
            if (error.response_code === 'ET'){
                localStorage.setItem("tokenExpired", true);
                setAuthenticated(false);
                return;
            } else {
                toast.current.show({ severity: 'error', summary: 'Gagal Mendapatkan Data Progress', detail: error.response_message, life: 2000 });
                setListDataTable([]);
                setTotalData(0);
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (!authenticated) {
            const loggedInUser = localStorage.getItem("authenticated");
            if (loggedInUser) {
                setAuthenticated(loggedInUser);
                setWaitAuthenticated(true);
                const filter = {
                    search: globalFilter,
                    first: first,
                    rows: rows
                }
                fetchData(filter);
            }else{
                setWaitAuthenticated(true);
            }
        }else{
            const filter = {
                search: globalFilter,
                first: first,
                rows: rows
            }
            fetchData(filter);
        }
    }, [refresh]);

    useEffect(() => {
        if (authenticated) {
            const delaySearch = setTimeout(async () => {
                const filter = {
                    search: globalFilter,
                    first: first,
                    rows: rows
                }
                fetchData(filter);
            }, 1000);
    
            return () => clearTimeout(delaySearch);
        }
    }, [globalFilter])

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(5);

        const filter = {
            search: globalFilter,
            first: event.first,
            rows: 5
        }
        fetchData(filter);
    };

    const onClickUnselect = (event) => {
        modalDialog('popupEdit', event)
    }

    const modalDialog = (name, data) => {
        if(name === 'popupEdit'){
            setNoLaporan('');
            setUraianKejadian('');
            setStatus('');

            setId(data.rowData.id);
            setTitleEdit(data.column.props.header);
            if(data.field === 'id2'){
                setIsTextArea(true);
                setFieldEdit('noLaporan');
                setValueEdit(data.rowData.noLaporan);
            }else if(data.field === 'id3'){
                setIsTextArea(true);
                setFieldEdit('uraian');
                setValueEdit(data.rowData.uraianKejadian);
            }else if(data.field === 'id4'){
                setIsTextArea(true);
                setFieldEdit('status');
                setValueEdit(data.rowData.status);
            }else if(data.field === 'id5'){
                setIsTextArea(true);
                setFieldEdit('keterangan');
                setValueEdit(data.rowData.keterangan);
            }else if(data.field === 'id6'){
                setIsTextArea(true);
                setFieldEdit('penyidikan');
                setValueEdit(data.rowData.penyidikan);
            }else if(data.field === 'id7'){
                setIsTextArea(true);
                setFieldEdit('berhentiLidik');
                setValueEdit(data.rowData.berhentiLidik);
            }else if(data.field === 'id8'){
                setIsTextArea(true);
                setFieldEdit('berhentiSidik');
                setValueEdit(data.rowData.berhentiSidik);
            }else{
                setIsTextArea(false);
                setFieldEdit(data.field);
                setValueEdit(data.value);
            }

            if(data.cellIndex >= 3 && data.cellIndex <= 9){
                setBulan('Bulan 1');
                setIdBulan(data.rowData.idBulan1);
            }else if(data.cellIndex >= 10 && data.cellIndex <= 18){
                setBulan('Bulan 2');
                setIdBulan(data.rowData.idBulan2);
            }else if(data.cellIndex >= 19 && data.cellIndex <= 23){
                setBulan('Bulan 3');
                setIdBulan(data.rowData.idBulan3);
            }else if(data.cellIndex >= 24 && data.cellIndex <= 28){
                setBulan('Bulan 4');
                setIdBulan(data.rowData.idBulan4);
            }else{
                setBulan('');
                setIdBulan('');
            }

            setEditDialog(true);
        } else {
            setId('');
            setNoLaporan('');
            setUraianKejadian('');
            setStatus('');

            setTitleEdit('');
            setFieldEdit('');
            setValueEdit('');
            setBulan('');
            setIdBulan('');

            setTambahDialog(true);
        }
    }

    const onClickSaveTambah = () => {
        if (noLaporan && uraianKejadian && status) {
            saveDataTambah();
        }
    }

    const saveDataTambah = async () => {
        try {
            setIsLoading(true);
            const data = {
                noLaporan: noLaporan,
                uraian: uraianKejadian,
                status: status
            }

            let createDataProgress = await createProgress(data);
            if (createDataProgress.response_code !== "00") {
                throw createDataProgress;
            }
            toast.current.show({ severity: 'success', summary: 'Tambah Progress Berhasil', detail: 'Progress berhasil ditambahkan', life: 2000 });
        } catch (error) {
            if (error.response_code === 'ET'){
                localStorage.setItem("tokenExpired", true);
                setAuthenticated(false);
                return;
            } else {
                toast.current.show({ severity: 'error', summary: 'Gagal Menambahkan Progress', detail: error.response_message, life: 2000 });
            }
        }
        setTambahDialog(false);
        setRefresh(refresh+1);
    }

    const onClickSaveEdit = () => {
        if (valueEdit) {
            saveDataEdit();
        }
    }

    const saveDataEdit = async () => {
        try {
            setIsLoading(true);
            const data = {
                id: id,
                idBulan: idBulan,
                bulan: bulan,
                fieldEdit: fieldEdit,
                valueEdit: valueEdit
            }

            let editDataProgress = await editProgress(data);
            if (editDataProgress.response_code !== "00") {
                throw editDataProgress;
            }
            toast.current.show({ severity: 'success', summary: 'Edit Progress Berhasil', detail: 'Progress berhasil edit', life: 2000 });
        } catch (error) {
            if (error.response_code === 'ET'){
                localStorage.setItem("tokenExpired", true);
                setAuthenticated(false);
                return;
            } else {
                toast.current.show({ severity: 'error', summary: 'Gagal Mengedit Progress', detail: error.response_message, life: 2000 });
            }
        }
        setEditDialog(false);
        setRefresh(refresh+1);
    }

    const onClickDelete = async (data) => {
        try {
            setIsLoading(true)
            let deleteDataProgress = await deleteProgress(data.id);
            if (deleteDataProgress.response_code !== "00") {
                throw deleteDataProgress;
            }
            toast.current.show({ severity: 'success', summary: 'Hapus Progress Berhasil', detail: 'Progress berhasil dihapus', life: 2000 });
        } catch (error) {
            if (error.response_code === 'ET'){
                localStorage.setItem("tokenExpired", true);
                setAuthenticated(false);
                return;
            } else {
                toast.current.show({ severity: 'error', summary: 'Gagal Menghapus Progress', detail: error.response_message, life: 2000 });
            }
        }
        setRefresh(refresh+1);
    }

    const reject = () => {
    }

    const confirmDelete = (data) => {
        confirmDialog({
            message: 'Apakah anda yakin mau menghapus data ini?',
            header: 'Hapus Progress',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Hapus',
            rejectLabel: 'Batal',
            accept: () => onClickDelete(data),
            reject: reject
        });
    };

    let header = (
        <div className="grid">
            <div className="col-12" style={{ padding: "0px" }}>
                <div className="field grid" style={{ padding: "15px 10px 5px 10px", margin: '0px' }}>
                    <div className="col-1">
                        <Button label="Tambah" style={{fontSize: '14px', height: '40px'}} icon="pi pi-plus" onClick={() => modalDialog('popupAdd')}></Button>
                    </div>
                    <div className="col-5">
                        <div style={{ margin: '0 0 0 20px' }}>Silahkan klik 2x pada kolom untuk mengedit kolom.</div>
                        <div style={{ margin: '5px 0 0 20px' }}>Kuning = Belum diupdate selama 10-15 hari</div>
                        <div style={{ margin: '5px 0 0 20px' }}>Merah = Belum diupdate selama lebih dari 15 hari</div>
                    </div>
                    <div className="col-6" style={{ textAlign: "right" }}>
                        <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                        <InputText type="search" style={{fontSize: '14px', height: '40px'}} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search" />
                    </div>
                </div>
            </div>
        </div>
    );

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column frozen header="NO LAPORAN POLISI" rowSpan={2} style={{minWidth: '200px'}} />
                <Column frozen header="URAIAN KEJADIAN" rowSpan={2} style={{minWidth: '200px'}} />
                <Column frozen header="STATUS" rowSpan={2} style={{minWidth: '100px'}} />
                <Column header="REALISASI BULAN KE-1" colSpan={7} />
                <Column header="REALISASI BULAN KE-2" colSpan={9} />
                <Column header="REALISASI BULAN KE-3" colSpan={5} />
                <Column header="REALISASI BULAN KE-4" colSpan={5} />
                <Column header="STATUS" colSpan={4} />
                <Column frozen alignFrozen="right" header="AKSI" rowSpan={2} style={{minWidth: '100px'}} />
            </Row>
            <Row>
                <Column header="SP GAS" style={{minWidth: '150px'}} />
                <Column header="SP LIDIK" style={{minWidth: '150px'}} />
                <Column header="SP WAS LIDIK" style={{minWidth: '150px'}} />
                <Column header="SRT UNDANGAN" style={{minWidth: '150px'}} />
                <Column header="SP2HP" style={{minWidth: '150px'}} />
                <Column header="VISUM" style={{minWidth: '150px'}} />
                <Column header="GELAR" style={{minWidth: '150px'}} />

                <Column header="SRT PANGGILAN" style={{minWidth: '150px'}} />
                <Column header="SP GAS" style={{minWidth: '150px'}} />
                <Column header="SP LIDIK" style={{minWidth: '150px'}} />
                <Column header="SP WAS LIDIK" style={{minWidth: '150px'}} />
                <Column header="SP2HP" style={{minWidth: '150px'}} />
                <Column header="GELAR" style={{minWidth: '150px'}} />
                <Column header="VISUM" style={{minWidth: '150px'}} />
                <Column header="MINDIK BARU" style={{minWidth: '150px'}} />
                <Column header="PERKEMBANGAN BLN KE 1" style={{minWidth: '200px'}} />
                
                <Column header="SRT PANGGILAN" style={{minWidth: '150px'}} />
                <Column header="SP2HP" style={{minWidth: '150px'}} />
                <Column header="GELAR" style={{minWidth: '150px'}} />
                <Column header="MINDIK BARU" style={{minWidth: '150px'}} />
                <Column header="PERKEMBANGAN BLN KE 2" style={{minWidth: '200px'}} />

                <Column header="SRT PANGGILAN" style={{minWidth: '150px'}} />
                <Column header="SP2HP" style={{minWidth: '150px'}} />
                <Column header="GELAR" style={{minWidth: '150px'}} />
                <Column header="MINDIK BARU" style={{minWidth: '150px'}} />
                <Column header="PERKEMBANGAN BLN KE 3" style={{minWidth: '200px'}} />
                
                <Column header="KETERANGAN" style={{minWidth: '150px'}} />
                <Column header="PENYIDIKAN" style={{minWidth: '150px'}} />
                <Column header="BERHENTI LIDIK" style={{minWidth: '150px'}} />
                <Column header="BERHENTI SIDIK" style={{minWidth: '150px'}} />
            </Row>
        </ColumnGroup>
    );

    const tambahFooter = () => {
        return (
            <div>
                <Button label="Batal" style={{ background: '#d40c0c', color: "white", border: '0px' }} onClick={() => setTambahDialog(false)} icon="pi pi-times" autoFocus />
                <Button label="Simpan" style={{ border: '0px' }} onClick={() => onClickSaveTambah()} icon="pi pi-save" autoFocus />
            </div>
        )
    }

    const editFooter = () => {
        return (
            <div>
                <Button label="Batal" style={{ background: '#d40c0c', color: "white", border: '0px' }} onClick={() => setEditDialog(false)} icon="pi pi-times" autoFocus />
                <Button label="Simpan" style={{ border: '0px' }} onClick={() => onClickSaveEdit()} icon="pi pi-save" autoFocus />
            </div>
        )
    }

    const isCellSelectable = (event) => (event.data.field === 'id9' ? false : true);

    const rowClass = (data) => {
        return {
            'bg-white': data.lastEditProgress > -10,
            'bg-red-300': data.lastEditProgress < -15,
            'bg-yellow-300': data.lastEditProgress > -16,
            'font-medium': data
        };
    };

    const noLaporanTemplate = (data) => {
        return (
            <div>{data.noLaporan}</div>
        );
    }

    const uraianTemplate = (data) => {
        return (
            <div>{data.uraian}</div>
        );
    }

    const statusTemplate = (data) => {
        return (
            <div>{data.status}</div>
        );
    }

    const keteranganTemplate = (data) => {
        return (
            <div>{data.keterangan}</div>
        );
    }

    const penyidikanTemplate = (data) => {
        return (
            <div>{data.penyidikan}</div>
        );
    }

    const berhentiLidikTemplate = (data) => {
        return (
            <div>{data.berhentiLidik}</div>
        );
    }

    const berhentiSidikTemplate = (data) => {
        return (
            <div>{data.berhentiSidik}</div>
        );
    }

    const spGas1Template = (data) => {
        return (
            <>
                <div>{data.spGas1}</div>
                <div>{data.tglSpGas1}</div>
            </>
        );
    }
    const spLidik1Template = (data) => {
        return (
            <>
                <div>{data.spLidik1}</div>
                <div>{data.tglSpLidik1}</div>
            </>
        );
    }
    const spWas1Template = (data) => {
        return (
            <>
                <div>{data.spWas1}</div>
                <div>{data.tglSpWas1}</div>
            </>
        );
    }
    const undangan1Template = (data) => {
        return (
            <>
                <div>{data.undangan1}</div>
                <div>{data.tglUndangan1}</div>
            </>
        );
    }
    const sp2hp1Template = (data) => {
        return (
            <>
                <div>{data.sp2hp1}</div>
                <div>{data.tglSp2hp1}</div>
            </>
        );
    }
    const visum1Template = (data) => {
        return (
            <>
                <div>{data.visum1}</div>
                <div>{data.tglVisum1}</div>
            </>
        );
    }
    const gelar1Template = (data) => {
        return (
            <>
                <div>{data.gelar1}</div>
                <div>{data.tglGelar1}</div>
            </>
        );
    }

    const panggilan2Template = (data) => {
        return (
            <>
                <div>{data.panggilan2}</div>
                <div>{data.tglPanggilan2}</div>
            </>
        );
    }
    const spGas2Template = (data) => {
        return (
            <>
                <div>{data.spGas2}</div>
                <div>{data.tglSpGas2}</div>
            </>
        );
    }
    const spLidik2Template = (data) => {
        return (
            <>
                <div>{data.spLidik2}</div>
                <div>{data.tglSpLidik2}</div>
            </>
        );
    }
    const spWas2Template = (data) => {
        return (
            <>
                <div>{data.spWas2}</div>
                <div>{data.tglSpWas2}</div>
            </>
        );
    }
    const sp2hp2Template = (data) => {
        return (
            <>
                <div>{data.sp2hp2}</div>
                <div>{data.tglSp2hp2}</div>
            </>
        );
    }
    const gelar2Template = (data) => {
        return (
            <>
                <div>{data.gelar2}</div>
                <div>{data.tglGelar2}</div>
            </>
        );
    }
    const visum2Template = (data) => {
        return (
            <>
                <div>{data.visum2}</div>
                <div>{data.tglVisum2}</div>
            </>
        );
    }
    const mindikBaru2Template = (data) => {
        return (
            <>
                <div>{data.mindikBaru2}</div>
                <div>{data.tglMindikBaru2}</div>
            </>
        );
    }
    const perkembangan2Template = (data) => {
        return (
            <>
                <div>{data.perkembangan2}</div>
                <div>{data.tglPerkembangan2}</div>
            </>
        );
    }

    const panggilan3Template = (data) => {
        return (
            <>
                <div>{data.panggilan3}</div>
                <div>{data.tglPanggilan3}</div>
            </>
        );
    }
    const sp2hp3Template = (data) => {
        return (
            <>
                <div>{data.sp2hp3}</div>
                <div>{data.tglSp2hp3}</div>
            </>
        );
    }
    const gelar3Template = (data) => {
        return (
            <>
                <div>{data.gelar3}</div>
                <div>{data.tglGelar3}</div>
            </>
        );
    }
    const mindikBaru3Template = (data) => {
        return (
            <>
                <div>{data.mindikBaru3}</div>
                <div>{data.tglMindikBaru3}</div>
            </>
        );
    }
    const perkembangan3Template = (data) => {
        return (
            <>
                <div>{data.perkembangan3}</div>
                <div>{data.tglPerkembangan3}</div>
            </>
        );
    }

    const panggilan4Template = (data) => {
        return (
            <>
                <div>{data.panggilan4}</div>
                <div>{data.tglPanggilan4}</div>
            </>
        );
    }
    const sp2hp4Template = (data) => {
        return (
            <>
                <div>{data.sp2hp4}</div>
                <div>{data.tglSp2hp4}</div>
            </>
        );
    }
    const gelar4Template = (data) => {
        return (
            <>
                <div>{data.gelar4}</div>
                <div>{data.tglGelar4}</div>
            </>
        );
    }
    const mindikBaru4Template = (data) => {
        return (
            <>
                <div>{data.mindikBaru4}</div>
                <div>{data.tglMindikBaru4}</div>
            </>
        );
    }
    const perkembangan4Template = (data) => {
        return (
            <>
                <div>{data.perkembangan4}</div>
                <div>{data.tglPerkembangan4}</div>
            </>
        );
    }

    const actionTemplate = (data) => {
        return (
            <div>
                <div>
                    <Button icon="pi pi-trash" label="Hapus" style={{ fontSize: '14px', textAlign: 'center', background: '#d40c0c', height: '30px', width: '100%', border: '0px' }} onClick={() => confirmDelete(data)} />
                </div>
            </div>
        );
    }

    if (!authenticated){
        return (
            !waitAuthenticated ?
            <></> :
            <Navigate replace to="/login" />
        )
    }else{
        return (
            <>
                <Header />
                <div>
                    <Panel header='Progress'>
                        <DataTable showGridlines value={listDataTable} size='small' header={header} headerColumnGroup={headerGroup} rowClassName={rowClass}
                        paginator lazy first={first} rows={rows * 6} totalRecords={totalData} onPage={onPageChange} style={{ fontSize: '14px' }}
                        scrollable cellSelection selectionMode="single" selection={selectedCell} onSelectionChange={(e) => setSelectedCell(e.value)}
                        isDataSelectable={isCellSelectable} onCellUnselect={onClickUnselect} metaKeySelection={false} rowGroupMode="rowspan" 
                        groupRowsBy={["id2", "id3", "id4", "id5", "id6", "id7", "id8", "id9"]}>
                            <Column field="id2" header="No Laporan Polisi" frozen style={{ textAlign: 'center' }} body={noLaporanTemplate}></Column>
                            <Column field="id3" header="Uraian Kejadian" frozen style={{ textAlign: 'center' }} body={uraianTemplate}></Column>
                            <Column field="id4" header="Status" frozen style={{ textAlign: 'center' }} body={statusTemplate}></Column>

                            <Column field="spGas1" header="SP Gas" body={spGas1Template} style={{ textAlign: 'center', height: '30px', backgroundColor: '#F9F9F9' }}></Column>
                            <Column field="spLidik1" header="SP Lidik" body={spLidik1Template} style={{ textAlign: 'center', backgroundColor: '#F9F9F9' }}></Column>
                            <Column field="spWas1" header="SP Was Lidik" body={spWas1Template} style={{ textAlign: 'center', backgroundColor: '#F9F9F9' }}></Column>
                            <Column field="undangan1" header="Srt Undangan" body={undangan1Template} style={{ textAlign: 'center', backgroundColor: '#F9F9F9' }}></Column>
                            <Column field="sp2hp1" header="SP2HP" body={sp2hp1Template} style={{ textAlign: 'center', backgroundColor: '#F9F9F9' }}></Column>
                            <Column field="visum1" header="Visum" body={visum1Template} style={{ textAlign: 'center', backgroundColor: '#F9F9F9' }}></Column>
                            <Column field="gelar1" header="Gelar" body={gelar1Template} style={{ textAlign: 'center', backgroundColor: '#F9F9F9' }}></Column>

                            <Column field="panggilan2" header="Srt Panggilan" body={panggilan2Template} style={{ textAlign: 'center', backgroundColor: '#F1F1F1' }}></Column>
                            <Column field="spGas2" header="SP Gas" body={spGas2Template} style={{ textAlign: 'center', backgroundColor: '#F1F1F1' }}></Column>
                            <Column field="spLidik2" header="SP Lidik" body={spLidik2Template} style={{ textAlign: 'center', backgroundColor: '#F1F1F1' }}></Column>
                            <Column field="spWas2" header="SP Was Lidik" body={spWas2Template} style={{ textAlign: 'center', backgroundColor: '#F1F1F1' }}></Column>
                            <Column field="sp2hp2" header="SP2HP" body={sp2hp2Template} style={{ textAlign: 'center', backgroundColor: '#F1F1F1' }}></Column>
                            <Column field="gelar2" header="Gelar" body={gelar2Template} style={{ textAlign: 'center', backgroundColor: '#F1F1F1' }}></Column>
                            <Column field="visum2" header="Visum" body={visum2Template} style={{ textAlign: 'center', backgroundColor: '#F1F1F1' }}></Column>
                            <Column field="mindikBaru2" header="Mindik Baru" body={mindikBaru2Template} style={{ textAlign: 'center', backgroundColor: '#F1F1F1' }}></Column>
                            <Column field="perkembangan2" header="Perkembangan Bln ke 1" body={perkembangan2Template} style={{ textAlign: 'center', backgroundColor: '#F1F1F1' }}></Column>

                            <Column field="panggilan3" header="Srt Panggilan" body={panggilan3Template} style={{ textAlign: 'center', backgroundColor: '#F1F1FA' }}></Column>
                            <Column field="sp2hp3" header="SP2HP" body={sp2hp3Template} style={{ textAlign: 'center', backgroundColor: '#F1F1FA' }}></Column>
                            <Column field="gelar3" header="Gelar" body={gelar3Template} style={{ textAlign: 'center', backgroundColor: '#F1F1FA' }}></Column>
                            <Column field="mindikBaru3" body={mindikBaru3Template} header="Mindik Baru" style={{ textAlign: 'center', backgroundColor: '#F1F1FA' }}></Column>
                            <Column field="perkembangan3" body={perkembangan3Template} header="Perkembangan Bln ke 2" style={{ textAlign: 'center', backgroundColor: '#F1F1FA' }}></Column>

                            <Column field="panggilan4" body={panggilan4Template} header="Srt Panggilan" style={{ textAlign: 'center', backgroundColor: '#F1FAF1' }}></Column>
                            <Column field="sp2hp4" body={sp2hp4Template} header="SP2HP" style={{ textAlign: 'center', backgroundColor: '#F1FAF1' }}></Column>
                            <Column field="gelar4" body={gelar4Template} header="Gelar" style={{ textAlign: 'center', backgroundColor: '#F1FAF1' }}></Column>
                            <Column field="mindikBaru4" body={mindikBaru4Template} header="Mindik Baru" style={{ textAlign: 'center', backgroundColor: '#F1FAF1' }}></Column>
                            <Column field="perkembangan4" body={perkembangan4Template} header="Perkembangan Bln ke 3" style={{ textAlign: 'center', backgroundColor: '#F1FAF1' }}></Column>

                            <Column field="id5" header="Keterangan" style={{ textAlign: 'center' }} body={keteranganTemplate}></Column>
                            <Column field="id6" header="Penyidikan" style={{ textAlign: 'center' }} body={penyidikanTemplate}></Column>
                            <Column field="id7" header="Berhenti Lidik" style={{ textAlign: 'center' }} body={berhentiLidikTemplate}></Column>
                            <Column field="id8" header="Berhenti Sidik" style={{ textAlign: 'center' }} body={berhentiSidikTemplate}></Column>
                            <Column field="id9" frozen alignFrozen="right" header="AKSI" style={{ width: '100px' }} body={actionTemplate}></Column>
                        </DataTable>
                    </Panel>
                    <Dialog header='Tambah Laporan Polisi' visible={tambahDialog} style={{ width: '50vw' }} onHide={() => setTambahDialog(false)} footer={tambahFooter()}>
                        <div className="field" style={{width: '80%', margin: '30px'}}>
                            <label for="noLaporan">No Laporan Polisi</label>
                            <div>
                                <InputTextarea id='noLaporan' value={noLaporan} onChange={(e) => setNoLaporan(e.target.value)} style={{width: '100%'}} autoResize />
                                <small></small>
                            </div>
                        </div>
                        <div className="field" style={{width: '80%', margin: '30px'}}>
                            <label for="uraianKejadian">Uraian Kejadian</label>
                            <div>
                                <InputTextarea id='uraianKejadian' value={uraianKejadian} onChange={(e) => setUraianKejadian(e.target.value)} style={{width: '100%'}} autoResize />
                                <small></small>
                            </div>
                        </div>
                        <div className="field" style={{width: '80%', margin: '30px'}}>
                            <label for="status">Status</label>
                            <div>
                                <InputTextarea id='status' value={status} onChange={(e) => setStatus(e.target.value)} style={{width: '100%'}} autoResize />
                                <small></small>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog header='Edit' visible={editDialog} style={{ width: '50vw' }} onHide={() => setEditDialog(false)} footer={editFooter()}>
                        <div className="field" style={{width: '80%', margin: '30px'}}>
                            <label for="valueEdit">{titleEdit}</label>
                            <div>
                                {isTextArea ?
                                    <InputTextarea id='valueEdit' value={valueEdit} onChange={(e) => setValueEdit(e.target.value)} style={{width: '100%'}} autoResize />
                                    : <InputText id='valueEdit' value={valueEdit} onChange={(e) => setValueEdit(e.target.value)} style={{width: '100%'}} />
                                }
                                <small></small>
                            </div>
                        </div>
                    </Dialog>
                    <ConfirmDialog />
                </div>
                <Footer />
                <Toast ref={toast} />
                <Loading loading={isLoading} />
            </>
        );
    }
}