import { Panel } from "primereact/panel";
import { Header } from '../component/header';
import { Footer } from '../component/footer';
import { Loading } from "../component/loading";
import { useEffect, useState, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { Navigate } from "react-router-dom";
import ReactPlayer from 'react-player';
import { createVideo, deleteVideo, editVideo, getVideo } from "../service/Video";

export const Video = (props) => {
    
    const [authenticated, setAuthenticated] = useState(null);
    const [waitAuthenticated, setWaitAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const toast = useRef(null);

    const [globalFilter, setGlobalFilter] = useState('');
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalData, setTotalData] = useState(0);

    const [popupDialog, setPopupDialog] = useState(false);
    const [statusFlag, setStatusFlag] = useState('');
    const [id, setId] = useState('');
    const [judul, setJudul] = useState('');
    const [deskripsi, setDeskripsi] = useState('');
    const [url, setUrl] = useState('');
    const [active, setActive] = useState('');

    const [listDataTable, setListDataTable] = useState([]);

    const fetchData = async (filter) => {
        setIsLoading(true);
        try {
            let listVideo = await getVideo(filter);
            if (listVideo.response_code !== "00") {
                throw listVideo;
            }
            setListDataTable(listVideo.data);
            setTotalData(listVideo.totalData);
        } catch (error) {
            if (error.response_code === 'ET'){
                localStorage.setItem("tokenExpired", true);
                setAuthenticated(false);
                return;
            } else {
                toast.current.show({ severity: 'error', summary: 'Gagal Mendapatkan Data Video', detail: error.response_message, life: 2000 });
                setListDataTable([]);
                setTotalData(0);
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (!authenticated) {
            const loggedInUser = localStorage.getItem("authenticated");
            if (loggedInUser) {
                setAuthenticated(loggedInUser);
                setWaitAuthenticated(true);
                const filter = {
                    search: globalFilter,
                    first: first,
                    rows: rows
                }
                fetchData(filter);
            }else{
                setWaitAuthenticated(true);
            }
        }else{
            const filter = {
                search: globalFilter,
                first: first,
                rows: rows
            }
            fetchData(filter);
        }
    }, [refresh]);

    const modalDialog = (name, data) => {
        setStatusFlag(name);
        if(name === 'popupEdit'){
            setId(data.id);
            setJudul(data.judul);
            setDeskripsi(data.deskripsi);
            setUrl(data.url);
            setActive(data.active);
        } else {
            setId('');
            setJudul('');
            setDeskripsi('');
            setUrl('');
            setActive('');
        }

        setPopupDialog(true);
    }

    const onClickDelete = async (data) => {
        try {
            setIsLoading(true)
            let deleteDataVideo = await deleteVideo(data.id);
            if (deleteDataVideo.response_code !== "00") {
                throw deleteDataVideo;
            }
            toast.current.show({ severity: 'success', summary: 'Hapus Video Berhasil', detail: 'Video berhasil dihapus', life: 2000 });
        } catch (error) {
            if (error.response_code === 'ET'){
                localStorage.setItem("tokenExpired", true);
                setAuthenticated(false);
                return;
            } else {
                toast.current.show({ severity: 'error', summary: 'Gagal Menghapus Video', detail: error.response_message, life: 2000 });
            }
        }
        setRefresh(refresh+1);
    }

    const reject = () => {
    }

    const confirmDelete = (data) => {
        confirmDialog({
            message: 'Apakah anda yakin mau menghapus video ini?',
            header: 'Hapus Video',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Hapus',
            rejectLabel: 'Batal',
            accept: () => onClickDelete(data),
            reject: reject
        });
    };

    const onClickSave = (name) => {
        if (judul && deskripsi && url) {
            saveData(name);
        }
    }

    const saveData = async (name) => {
        if (name === 'popupEdit'){
            try {
                setIsLoading(true);
                const data = {
                    id: id,
                    judul: judul,
                    deskripsi: deskripsi,
                    url: url,
                    active: active
                }

                let editDataVideo = await editVideo(data);
                if (editDataVideo.response_code !== "00") {
                    throw editDataVideo;
                }
                toast.current.show({ severity: 'success', summary: 'Edit Video Berhasil', detail: 'Video berhasil edit', life: 2000 });
            } catch (error) {
                if (error.response_code === 'ET'){
                    localStorage.setItem("tokenExpired", true);
                    setAuthenticated(false);
                    return;
                } else {
                    toast.current.show({ severity: 'error', summary: 'Gagal Mengedit Video', detail: error.response_message, life: 2000 });
                }
            }
        } else {
            try {
                setIsLoading(true);
                const data = {
                    judul: judul,
                    deskripsi: deskripsi,
                    url: url,
                    active: active
                }

                let createDataVideo = await createVideo(data);
                if (createDataVideo.response_code !== "00") {
                    throw createDataVideo;
                }
                toast.current.show({ severity: 'success', summary: 'Tambah Video Berhasil', detail: 'Video berhasil ditambahkan', life: 2000 });
            } catch (error) {
                if (error.response_code === 'ET'){
                    localStorage.setItem("tokenExpired", true);
                    setAuthenticated(false);
                    return;
                } else {
                    toast.current.show({ severity: 'error', summary: 'Gagal Menambahkan Video', detail: error.response_message, life: 2000 });
                }
            }
        }
        setPopupDialog(false);
        setRefresh(refresh+1);
    }

    useEffect(() => {
        if (authenticated) {
            const delaySearch = setTimeout(async () => {
                const filter = {
                    search: globalFilter,
                    first: first,
                    rows: rows
                }
                fetchData(filter);
            }, 1000);
    
            return () => clearTimeout(delaySearch);
        }
    }, [globalFilter])

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);

        const filter = {
            search: globalFilter,
            first: event.first,
            rows: event.rows
        }
        fetchData(filter);
    };

    let header = (
        <div className="grid">
            <div className="col-12" style={{ padding: "0px" }}>
                <div className="field grid" style={{ padding: "15px 10px 5px 10px", margin: '0px' }}>
                    <div className="col-6">
                        <Button label="Tambah" style={{fontSize: '14px', height: '40px'}} icon="pi pi-plus" onClick={() => modalDialog('popupAdd')}></Button>
                    </div>
                    <div className="col-6" style={{ textAlign: "right" }}>
                        <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                        <InputText type="search" style={{fontSize: '14px', height: '40px'}} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search" />
                    </div>
                </div>
            </div>
        </div>
    );

    const videoTemplate = (data) => {
        return (
            <div>
                <ReactPlayer url={data.url} muted={false} controls={true} width='420px' height='240px' />
            </div>
        );
    }

    const actionTemplate = (data) => {
        return (
            <div>
                <div>
                    <Button icon="pi pi-user-edit" label="Edit" style={{ fontSize: '14px', textAlign: 'center', background: '#f45700ed', height: '30px', width: '100%', marginBottom: '8px', border: '0px' }} onClick={() => modalDialog('popupEdit', data)} />
                </div>
                <div>
                    <Button icon="pi pi-trash" label="Hapus" style={{ fontSize: '14px', textAlign: 'center', background: '#d40c0c', height: '30px', width: '100%', border: '0px' }} onClick={() => confirmDelete(data)} />
                </div>
            </div>
        );
    }

    const popupFoot = (name) => {
        return (
            <div>
                <Button label="Batal" style={{ background: '#d40c0c', color: "white", border: '0px' }} onClick={() => setPopupDialog(false)} icon="pi pi-times" autoFocus />
                <Button label="Simpan" style={{ border: '0px' }} onClick={() => onClickSave(name)} icon="pi pi-save" autoFocus />
            </div>
        )
    }

    if (!authenticated){
        return (
            !waitAuthenticated ?
            <></> :
            <Navigate replace to="/login" />
        )
    }else{
        return (
            <>
                <Header />
                <div className="body">
                    <Panel header='Video'>
                        <DataTable showGridlines value={listDataTable} size='small' header={header}
                        paginator lazy first={first} rows={rows} totalRecords={totalData} rowsPerPageOptions={[5, 10, 25]} onPage={onPageChange}>
                            <Column field="judul" header="JUDUL" style={{ textAlign: 'center' }}></Column>
                            <Column field="data" header="PREVIEW" body={videoTemplate} style={{ textAlign: 'center', width: '430px' }}></Column>
                            <Column field="deskripsi" header="DESKRIPSI" style={{ textAlign: 'center' }}></Column>
                            <Column field="url" header="URL" style={{ textAlign: 'center' }}></Column>
                            <Column field="activeText" header="AKTIF" style={{ textAlign: 'center' }}></Column>
                            <Column field="data" header="AKSI" body={actionTemplate} headerStyle={{ width: '10%' }}></Column>
                        </DataTable>
                    </Panel>
                    <Dialog header={statusFlag === 'popupEdit' ? 'Edit Video' : 'Tambah Video'} visible={popupDialog} style={{ width: '50vw' }} onHide={() => setPopupDialog(false)} footer={popupFoot(statusFlag)}>
                        <div className="field" style={{width: '50%', margin: '30px'}}>
                            <label for="judul">Judul</label>
                            <div>
                                <InputText id='judul' value={judul} onChange={(e) => setJudul(e.target.value)} style={{width: '100%'}} />
                                <small></small>
                            </div>
                        </div>
                        <div className="field" style={{width: '50%', margin: '30px'}}>
                            <label for="deskripsi">Deskripsi</label>
                            <div>
                                <InputText id='deskripsi' value={deskripsi} onChange={(e) => setDeskripsi(e.target.value)} style={{width: '100%'}} />
                                <small></small>
                            </div>
                        </div>
                        <div className="field" style={{width: '50%', margin: '30px'}}>
                            <label for="url">Url</label>
                            <div>
                                <InputText id='url' value={url} onChange={(e) => setUrl(e.target.value)} style={{width: '100%'}} />
                                <small></small>
                            </div>
                        </div>
                        <div className="field" style={{width: '50%', margin: '30px'}}>
                            <label for="url">Preview Video</label>
                            <div>
                                {url ? <ReactPlayer url={url} muted={false} controls={true} width='420px' height='240px' /> : <small style={{color: '#EA0000'}}>Mohon isi kolom url untuk melihat preview video</small>}
                            </div>
                        </div>
                        <div className="field" style={{width: '50%', margin: '30px'}}>
                            <label style={{marginBottom: '10px'}}>Tampilkan Video Pada Menu Play</label>
                            <div style={{ alignItems: 'center', display: 'flex' }}>
                                <InputSwitch inputId="active" checked={active} name='tes' falseValue={0} trueValue={1} onChange={(e) => setActive(e.value)} />
                                <label for="active" style={{marginLeft: '10px'}}>{active ? 'Aktif' : 'Tidak Aktif'}</label>
                                <small></small>
                            </div>
                        </div>
                    </Dialog>
                    <ConfirmDialog />
                </div>
                <Footer />
                <Toast ref={toast} />
                <Loading loading={isLoading} />
            </>
        );
    }
}