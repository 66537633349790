import { useEffect, useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ReactPlayer from 'react-player';
import { Navigate } from "react-router-dom";
import { Toast } from 'primereact/toast';
import { Loading } from "../component/loading";
import { getVideoPlay } from "../service/Video";
import { getTahananPlay } from "../service/Tahanan";

export const Play = (props) => {
    
    const [authenticated, setAuthenticated] = useState(null);
    const [waitAuthenticated, setWaitAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef(null);
    
    const [playState, setPlayState] = useState(false);
    const [dataState, setDataState] = useState(true);
    const [repeat, setRepeat] = useState(0);

    const [listTahanan, setListTahanan] = useState([]);
    const [listVideo, setListVideo] = useState([]);
    const [videoUrl, setVideoUrl] = useState('');

    const fetchData = async () => {
        setIsLoading(true);
        try {
            let listTahananPlay = await getTahananPlay();
            if (listTahananPlay.response_code !== "00") {
                throw listTahananPlay;
            }
            setListTahanan(listTahananPlay.data);
        } catch (error) {
            if (error.response_code === 'ET'){
                localStorage.setItem("tokenExpired", true);
                setAuthenticated(false);
                return;
            } else {
                toast.current.show({ severity: 'error', summary: 'Gagal Mendapatkan Data Tahanan', detail: error.response_message, life: 2000 });
                setListTahanan([]);
            }
        }
        try {
            let listVideoPlay = await getVideoPlay();
            if (listVideoPlay.response_code !== "00") {
                throw listVideoPlay;
            }
            setListVideo(listVideoPlay.data);
            if (listVideoPlay.data.length !== 0) {
                startLoop(listVideoPlay.data);
            }
        } catch (error) {
            if (error.response_code === 'ET'){
                localStorage.setItem("tokenExpired", true);
                setAuthenticated(false);
                return;
            } else {
                toast.current.show({ severity: 'error', summary: 'Gagal Mendapatkan Data Video', detail: error.response_message, life: 2000 });
                setListVideo([]);
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        const loggedInUser = localStorage.getItem("authenticated");
        if (loggedInUser) {
            setAuthenticated(loggedInUser);
            setWaitAuthenticated(true);
            fetchData();
        }else{
            setWaitAuthenticated(true);
        }
    }, []);
    
    useEffect(() => {
        startLoop(listVideo);
    }, [repeat]);

    const startLoop = (videoList) => {
        if (videoList.length === 1) {
            setVideoUrl(videoList[0].url);
            setTimeout(() => {
                hideData();
            }, 30000);
        } else if (videoList.length > 1) {
            setVideoUrl(videoList[repeat].url);
            setTimeout(() => {
                hideData();
            }, 30000);
        }
    }

    const showData = () => {
        setDataState(true);
        if(listVideo.length === 1){
            setRepeat(repeat+1);
        } else if (repeat === (listVideo.length - 1)) {
            setRepeat(0);
        } else {
            setRepeat(repeat+1);
        }
    }

    const hideData = async () => {
        setDataState(false);
        setTimeout(() => {
            setPlayState(true);
        }, 1000)
    }

    const rowClass = (data) => {
        return {
            'bg-red-300': data.sisaAngka < 6,
            'bg-yellow-300': data.sisaAngka < 11,
            'font-medium': data
        };
    };

    if (!authenticated){
        return (
            !waitAuthenticated ?
            <></> :
            <Navigate replace to="/login" />
        )
    }else{
        return (
            <div className="body" style={{paddingTop: '25px', paddingBottom: '25px'}}>
                <div id='overlay-play'></div>
                {   
                    dataState ? 
                    <DataTable showGridlines value={listTahanan} rowClassName={rowClass} size='small'>
                        <Column field="namaTahanan" header="NAMA TAHANAN" style={{ textAlign: 'center' }}></Column>
                        <Column field="tglMasuk" header="TANGGAL MASUK" style={{ textAlign: 'center' }}></Column>
                        <Column field="tglKeluar" header="TANGGAL KELUAR" style={{ textAlign: 'center' }}></Column>
                        <Column field="sisaHari" header="SISA HARI" style={{ textAlign: 'center' }}></Column>
                    </DataTable> : 
                    <ReactPlayer className='react-player' url={videoUrl} playing={playState} muted={false} controls={false} width='100%' height='100%' style={{overflow: 'hidden'}} onEnded={showData} />
                }
                <Toast ref={toast} />
                <Loading loading={isLoading} />
            </div>
        );
    }
}

